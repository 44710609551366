import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Switch,
  Snackbar,
  Alert,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // PDF icon
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const NewsletterList = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [expandedEventId, setExpandedEventId] = useState(null); // Track expanded newsletter
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [error, setError] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newsletterToDelete, setNewsletterToDelete] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get location

  // Filter newsletters based on search term
  const filteredNewsletters = newsletters.filter((newsletter) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      newsletter.title.toLowerCase().includes(lowerSearchTerm) ||
      newsletter.content.toLowerCase().includes(lowerSearchTerm) ||
      newsletter.author.toLowerCase().includes(lowerSearchTerm) ||
      new Date(newsletter.publish_date)
        .toLocaleDateString()
        .includes(lowerSearchTerm)
    );
  });

  // Fetch newsletters from the backend
  const fetchNewsletters = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/newsletter`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
            },
          }
        );
        setNewsletters(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching newsletters");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);

      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    fetchNewsletters();
  }, [searchTerm, page, rowsPerPage]);

  const handleAddNewsletter = () => {
    navigate("/newsletters/add");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    setNewsletters((prev) =>
      prev.map((newsletter) =>
        newsletter._id === id
          ? { ...newsletter, status: newStatus }
          : newsletter
      )
    );
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/newsletter/${id}/status_newsletter`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Newsletter status updated successfully");
        setSnackbarOpen(true);
      }
    } catch {
      setNewsletters((prev) =>
        prev.map((newsletter) =>
          newsletter._id === id
            ? { ...newsletter, status: currentStatus }
            : newsletter
        )
      );
      setSnackbarMessage("Failed to update newsletter status");
      setSnackbarOpen(true);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleEditNewsletter = (id) => {
    navigate(`/newsletters/edit/${id}`);
  };

  const handleOpenDeleteDialog = (id) => {
    setNewsletterToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setNewsletterToDelete(null);
  };

  const handleDeleteNewsletter = async () => {
    try {
      const token = getToken();
      if (token && newsletterToDelete) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/newsletter/delete/${newsletterToDelete}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchNewsletters(); // Refresh the list after deletion
        setSnackbarMessage("Newsletter deleted successfully");
        setSnackbarOpen(true);
      }
    } catch (err) {
      setError("Error deleting newsletter");
      console.error(err);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenPDF = (url) => {
    window.open(url, "_blank");
  };

  const toggleExpand = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">Newsletters</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewsletter}>
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20px" }}> No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>File URL</TableCell>
                <TableCell>Published Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNewsletters.length > 0 ? (
                filteredNewsletters.map((newsletter, index) => (
                  <TableRow key={newsletter._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{newsletter.title}</TableCell>
                    <TableCell>
                      <Typography>
                        {expandedEventId === newsletter._id ? (
                          <span>
                            {newsletter.content}
                            <Button
                              onClick={() => toggleExpand(newsletter._id)}
                              size="small">
                              Show Less
                            </Button>
                          </span>
                        ) : (
                          <span>
                            {newsletter.content.length > 100
                              ? `${newsletter.content.slice(0, 100)}...`
                              : newsletter.content}
                            {newsletter.content.length > 100 && (
                              <Button
                                onClick={() => toggleExpand(newsletter._id)}
                                size="small">
                                Read More
                              </Button>
                            )}
                          </span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>{newsletter.author}</TableCell>
                    <TableCell>
                      {newsletter.file_url ? (
                        <IconButton
                          onClick={() => handleOpenPDF(newsletter.file_url)}>
                          <PictureAsPdfIcon color="error" />
                        </IconButton>
                      ) : (
                        <Typography color="textSecondary">No File</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {new Date(newsletter.publish_date).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={newsletter.status}
                        onChange={() =>
                          handleStatusToggle(newsletter._id, newsletter.status)
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                    <Box   display="flex"
                          flexDirection="column"
                          alignItems="center" // Optional, aligns buttons horizontally in the center
                        >
                        <IconButton
                          color="primary"
                          onClick={() => handleEditNewsletter(newsletter._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDeleteDialog(newsletter._id)}>
                          <DeleteIcon />
                        </IconButton>
                    </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No newsletters found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Newsletter</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this newsletter? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteNewsletter} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default NewsletterList;

import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Snackbar, Alert } from '@mui/material';
import { getToken } from '../../../utils/AuthUtils';

const AddPartnerModal = ({ open, onClose, onAdd }) => {
  const [partnerName, setPartnerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  const handleChange = (event) => {
    setPartnerName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const token = getToken();
      if (token) {
        // Check if a category with the same name already exists
        const checkResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/partner/category`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await checkResponse.json();
        // Check if the partner name already exists
        const isDuplicate = data.data.some(
          (category) => category.partner_name.toLowerCase() === partnerName.toLowerCase()
        );

        if (isDuplicate) {
          setSnackbarMessage('Category with this name already exists');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
          setLoading(false); // Stop loading
          return; // Prevent further action
        }

        // Proceed to add the category if no duplicates found
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/partner/category`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ partner_name: partnerName }),
        });

        if (response.ok) {
          setSnackbarMessage('Category added successfully');
          setSnackbarSeverity('success');
          onAdd(); // Notify parent to refresh data
          setPartnerName(''); // Clear the input field
          onClose(); // Close the modal
        } else {
          setSnackbarMessage('Failed to add partner category');
          setSnackbarSeverity('error');
        }
      }
    } catch (error) {
      setSnackbarMessage('Error occurred while adding partner category');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="  Name"
              value={partnerName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Add'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddPartnerModal;

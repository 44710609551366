import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';

const EditProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({
    product_name: '',
    product_category_id: '',
    product_sub_category_name: '',
    course_id: '',
    department_id: '',
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken();
        if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/product/category`,
          {params: {
            pagination: 0
          },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setCategories(response.data.data || []);
      } }catch (err) {
        console.error('Failed to fetch categories', err);
      }
    };

    const fetchSubCategories = async () => {
      try { const token = getToken();
        if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/product/category_sub`,
          {
            params: {
              pagination: 0
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        setSubCategories(response.data.data || []);
      }} catch (err) {
        console.error('Failed to fetch subcategories', err);
      }
    };

    const fetchCourses = async () => {
      try {
        const token = getToken();
        if (token) {
        const coursesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/without_pagination`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCourses(coursesResponse.data.data || []);
      }} catch (err) {
        console.error('Failed to fetch courses', err);
      }
    };

    const fetchDepartments = async () => {
      try {
        const token = getToken();
        if (token) {
        const departmentsResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`,{
          params: {
            pagination: 0
          },
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setDepartments(departmentsResponse.data.data || []);
      } }catch (err) {
        console.error('Failed to fetch departments', err);
      }
    };

    fetchDepartments();
    fetchCourses();
    fetchCategories();
    fetchSubCategories();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const token = getToken();
        if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/product/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedProduct = response.data.data;
        setProduct(fetchedProduct);
        const filtered = subCategories.filter(subcategory => subcategory.product_id === fetchedProduct.product_category_id);
        setFilteredSubCategories(filtered);
      }} catch (err) {
        console.error('Failed to fetch product', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (subCategories.length > 0) {
      fetchProduct();
    }
  }, [id, subCategories]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'product_category_id') {
      const filtered = subCategories.filter(subcategory => subcategory.product_id === value);
      setFilteredSubCategories(filtered);
      setProduct((prev) => ({
        ...prev,
        product_sub_category_name: '', // Reset subcategory
      }));
    }
  };

  const validateForm = () => {
    const { product_name, product_category_id, product_sub_category_name, department_id } = product;
    if (!product_name || !product_category_id || !product_sub_category_name  || !department_id) {
      setError('All fields are required');
      return false;
    }
    setError(null);
    return true;
  };
  const handleCancel = () => {
    navigate('/product_list'); // Navigate back to the product list
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackbarMessage(error);
      setSnackbarOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/product/${id}`, product, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate('/product_list', { state: { snackbarMessage: 'Product updated successfully' } });
      }
    } catch (err) {
      setError('Failed to update product');
      setSnackbarMessage('Failed to update product');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box p={3}>
      <h1>EDIT PRODUCT</h1>
      <form onSubmit={handleSubmit}>
{/* Department Selection */}
<FormControl fullWidth margin="normal">
          <InputLabel>Department</InputLabel>
          <Select
            name="department_id"
            value={product.department_id}
            onChange={handleChange}
            label="Department"
          >
            {departments.map(department => (
              <MenuItem key={department._id} value={department._id}>
                {department.department_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      
        <FormControl fullWidth margin="normal">
          <InputLabel>Product Category</InputLabel>
          <Select
            name="product_category_id"
            value={product.product_category_id}
            onChange={handleChange}
            label="Product Category"
          >
            {categories.map(category => (
              <MenuItem key={category._id} value={category._id}>
                {category.product_category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Product Subcategory</InputLabel>
          <Select
            name="product_sub_category_name"
            value={product.product_sub_category_name}
            onChange={handleChange}
            label="Product Subcategory"
          >
            {filteredSubCategories.length > 0 ? (
              filteredSubCategories.map(subCategory => (
                <MenuItem key={subCategory._id} value={subCategory._id}>
                  {subCategory.product_sub_category_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>No subcategories available</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          label="Product Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="product_name"
          value={product.product_name}
          onChange={handleChange}
        />
        
        {/* Course Selection */}
        {/* <FormControl fullWidth margin="normal">
          <InputLabel>Course</InputLabel>
          <Select
            name="course_id"
            value={product.course_id}
            onChange={handleChange}
            label="Course"
          >
            {courses.map(course => (
              <MenuItem key={course._id} value={course._id}>
                {course.courses_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        

        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            disabled={isLoading}
            style={{ marginLeft: '16px' }}
          >
            Cancel
          </Button>
        </Box>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditProduct;

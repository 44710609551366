import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";
import { Container } from "@mui/system";

function AddCourse({ open, onClose, onAdd }) {
  const [partnerName, setPartnerName] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errors, setErrors] = useState({
    subCategoryName: "",
    partnerName: "",
    fetchPartners: "",
    api: "",
  });

  const handlePartnerChange = (e) => {
    setPartnerName(e.target.value);
  };

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = getToken();
      if (token) {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/courses_category`,
        {
          method: "POST",
          headers: { 
              Authorization: `Bearer ${token}`,
            "Content-Type": "application/json" },
          body: JSON.stringify({ courses_name: partnerName }),
        }
      );
      if (response.ok) {
        setSnackbarMessage("Category added successfully");
        setPartnerName("");
        onAdd(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        setSnackbarMessage("Failed to add Course");
      }

      setOpenSnackbar(true);
    } }catch (error) {
      setSnackbarMessage("Error occurred");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Dialog open={open} onClose={onClose}   maxWidth="sm" fullWidth >
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <form onSubmit={handlePartnerSubmit}>
            <TextField
              label=" Name"
              value={partnerName}
              onChange={handlePartnerChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            {/* <Box display="flex" justifyContent="center" marginTop={2}>
              {loading ? (
                <CircularProgress />
              ) }
            </Box> */}
          </form>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" onClick={handlePartnerSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
  open={openSnackbar}
  autoHideDuration={3000}
  onClose={handleSnackbarClose}
>
  <Alert
    onClose={handleSnackbarClose}
    severity={snackbarMessage.includes("Error") ? "error" : "success"}
  >
    {Object.values(errors).some(error => error) ? Object.values(errors).join(", ") : snackbarMessage}
  </Alert>
</Snackbar>

    </Container>
  );
}

export default AddCourse;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Snackbar,
  Alert,
  Grid,
  Container,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditLabname = () => {
  const [labName, setLabName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const [departmentSubCatId, setDepartmentSubCatId] = useState("");
  const [departmentCatId, setDepartmentCatId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    fetchDepartmentsaved(id);
  }, [id]);
  const fetchDepartmentsaved = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/labnames/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      // Check if the response is successful (status 200)
      if (!response.ok) {
        throw new Error(`Error fetching labname: ${response.statusText}`);
      }

      const data = await response.json();

      // Log the response to confirm the structure
      console.log("API Response:", data);

      // Check if data and data.data exist and are structured correctly
      if (data && data.data) {
        const labData = data.data;

        // Safely access the properties in labData
        setLabName(labData.lab_name || ""); // Default to empty string if lab_name is missing
        setDepartmentCatId(
          labData.department ? labData.department.department_category_id : ""
        );
        setDepartmentSubCatId(
          labData.department
            ? labData.department.department_sub_category_id
            : ""
        );
        setDepartmentId(labData.department ? labData.department._id : "");
      } else {
        setError("Labname not found or data is missing.");
      }
    } catch (err) {
      setError(`Failed to fetch labname: ${err.message}`);
      console.error("Error during API call:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDepartmentsCat = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/department_category/nopagination`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCategories(response.data.data); // Adjust based on your API response
        }
      } catch (err) {
        setError("Failed to fetch departments.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchDepartmentsCat();
  }, []);

  useEffect(() => {
    if (departmentCatId) {
      console.log("inside dep cat");

      const fetchDepartmentSubCategories = async () => {
        setLoading(true);
        try {
          const token = getToken();
          if (token) {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/department_category_sub/nopagnation/cat/${departmentCatId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // params: { category_id: departmentCatId },
              }
            );
            const data = response.data.data || [];
            setSubCategories(
              data.filter((subCategory) => subCategory.status === 1)
            );
          }
        } catch (err) {
          setError("Failed to fetch course Subcategory.");
        } finally {
          setLoading(false);
        }
      };

      fetchDepartmentSubCategories();
    } else {
      setSubCategories([]);
      setFilteredSubCategories([]);
    }
  }, [departmentCatId]);

  useEffect(() => {
    if (departmentSubCatId) {
      console.log("inside dep cat");

      const fetchDepartments = async () => {
        setLoading(true);
        try {
          const token = getToken();
          if (token) {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/department/nopagination/${departmentCatId}/${departmentSubCatId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                // params: { category_id: departmentCatId },
              }
            );
            const data = response.data.data || [];
            setDepartments(
              data.filter((department) => department.status === 1)
            );
          }
        } catch (err) {
          setError("Failed to fetch department.");
        } finally {
          setLoading(false);
        }
      };
      fetchDepartments();
    } else {
      setSubCategories([]);
      setFilteredSubCategories([]);
    }
  }, [departmentSubCatId, departmentCatId]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!labName) errors.labName = "Lab name is required";
    if (!departmentId) errors.departmentId = "Department is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return; // Prevent submission if form is invalid

    setError(""); // Clear any previous error messages

    try {
      const token = getToken();
      if (token) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/labnames/${id}`,
          {
            department_category: departmentCatId, // Include department category
            department_subcategory: departmentSubCatId, // Include departmet subcategory
            lab_name: labName,
            department: departmentId,
            status: true, // Set default status, modify as needed
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          // Store success message in sessionStorage
          sessionStorage.setItem(
            "labnameSuccessMessage",
            "Lab name added successfully"
          );
          // Redirect to Labnames list
          navigate("/labnames");
        }
      }
    } catch (err) {
      setError("Error adding lab name");
      console.error(err);
    }
  };

  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="100%" sx={{ marginLeft: "0px" }}>
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Edit Lab Name</Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Lab Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={labName}
            onChange={(e) => {
              setLabName(e.target.value);
              setFormErrors({ ...formErrors, labName: null }); // Clear error if valid
            }}
            required
            error={!!formErrors.labName}
            helperText={formErrors.labName}
          />
          <Grid item xs={12} md={6}>
            <FormControl
              margin="normal"
              variant="outlined"
              required
              sx={{ width: "100%" }}
            >
              <InputLabel>Department Category</InputLabel>
              <Select
                value={departmentCatId}
                onChange={(e) => setDepartmentCatId(e.target.value)}
                label="Department Category"
                aria-required="true"
              >
                {Categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.department_category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              margin="normal"
              variant="outlined"
              required
              sx={{ width: "100%" }}
            >
              <InputLabel>Department Sub Category</InputLabel>
              <Select
                value={departmentSubCatId}
                onChange={(e) => setDepartmentSubCatId(e.target.value)}
                label="Department Sub Category"
                aria-required="true"
                disabled={!departmentCatId} // Disable if no category is selected
              >
                {subCategories.map((subCategory) => (
                  <MenuItem key={subCategory._id} value={subCategory._id}>
                    {subCategory.department_sub_category_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <FormControl
            fullWidth
            margin="normal"
            required
            error={!!formErrors.departmentId}
          >
            <InputLabel>Department</InputLabel>
            <Select
              value={departmentId}
              onChange={(e) => {
                setDepartmentId(e.target.value);
                setFormErrors({ ...formErrors, departmentId: null }); // Clear error if valid
              }}
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </Select>
            {formErrors.departmentId && (
              <FormHelperText>{formErrors.departmentId}</FormHelperText>
            )}
          </FormControl>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mt={2}
          >
            
            <Button
              type="button"
              color="primary"
              sx={{ mr: 2 }} // Adjust margin as needed
              onClick={handleCancel} // Cancel button to go back
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" sx={{backgroundColor:'#e0e0e0'}}>
              Edit Lab Name
            </Button>

          </Box>
        </form>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditLabname;

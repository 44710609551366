import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import EditProductSub from "./EditProductSub";
import AddProductSub from "./AddProductSub";
import { getToken } from "../../../utils/AuthUtils";

function ProductSubList() {
  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(""); // State for selected category
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  // Fetch product categories
  const fetchCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/category/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Error fetching categories");
      setOpenSnackbar(true);
    }
  };

  // Fetch product Subcategory
  const fetchSubjects = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/category_sub`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { page: page + 1, limit: rowsPerPage, search: searchTerm },
          }
        );
        setSubjects(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      }
    } catch (err) {
      setError("Error fetching subjects");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSubjects();
  }, [page, rowsPerPage, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
    setPage(0); // Reset to first page when changing category
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSubjectId(null);
  };

  const handleDeleteSubject = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/product/category_sub/delete/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Sub-Category deleted successfully");
        setOpenSnackbar(true);
        fetchSubjects();
      }
    } catch (err) {
      setSnackbarMessage("Failed to delete Sub-Category");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/category_sub/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedSubject(response.data.data);
        setSelectedSubjectId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setError("Error fetching Sub-Category details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSubjectId(null);
    setSelectedSubject(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddSubject = () => {
    setSnackbarMessage("Sub-Category added successfully");
    setOpenSnackbar(true);
    handleCloseAddModal();
    fetchSubjects();
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateSubjectStatus(id, newStatus);
  };

  const updateSubjectStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await fetch(
          `${process.env.REACT_APP_BACKEND}/api/product/category_sub/${id}/status_product_sub`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status }),
          }
        );
        setSnackbarMessage("Sub-Category status updated successfully");
        setOpenSnackbar(true);
        fetchSubjects();
      }
    } catch (err) {
      setError("Failed to update Sub-Category status");
      setOpenSnackbar(true);
    }
  };

  const filteredCategories = subjects.filter(
    (subject) =>
      subject.product_sub_category_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      (selectedCategoryId ? subject.product_id === selectedCategoryId : true) // Filter by selected category
  );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Product Subcategory</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{
          paddingLeft: "0",
          paddingRight: "0",
        }}
      >
        <TextField
          select
          label="Select Category"
          value={selectedCategoryId}
          onChange={handleCategoryChange}
          variant="outlined"
          size="small"
          sx={{ width: "200px" }}
        >
          <MenuItem value="">All</MenuItem>
          {categories
            .sort((a, b) =>
              a.product_category_name.localeCompare(
                b.product_category_name,
                "en",
                {
                  sensitivity: "base",
                }
              )
            ) // Sort alphabetically A to Z
            .map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.product_category_name}
              </MenuItem>
            ))}
        </TextField>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell> Subcategory </TableCell>
              <TableCell>Category </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((subject, index) => {
                const category = categories.find(
                  (cat) => cat._id === subject.product_id
                );
                return (
                  <TableRow key={subject._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{subject.product_sub_category_name}</TableCell>
                    <TableCell>
                      {category ? category.product_category_name : "N/A"}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={subject.status}
                        onChange={() =>
                          handleStatusToggle(subject._id, subject.status)
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditModal(subject._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDeleteDialog(subject._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Subcategory found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddProductSub
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddSubject}
        categories={categories} // Pass categories to the AddProductSub component
      />
      {selectedSubjectId && (
        <EditProductSub
          open={openEditModal}
          onClose={handleCloseEditModal}
          subjectId={selectedSubjectId}
          subject={selectedSubject}
          categories={categories} // Pass categories to the EditProductSub component
          onUpdate={() => {
            setSnackbarMessage("Sub-Category Updated successfully");
            setOpenSnackbar(true);
            handleCloseEditModal();
            fetchSubjects();
          }}
        />
      )}
      {selectedSubjectId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSubject(selectedSubjectId)}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProductSubList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Container,
  Autocomplete, // Import Autocomplete
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
import { FormControl as ReactBootstrapFormControl } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";

const PdfList = () => {
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [openDialog, setOpenDialog] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [searchQuery, setSearchQuery] = useState("");

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [courseCategories, setCourseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [selectedCourses, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);

  // chapters filter states
  const [selectedChapters, setSelectedChapters] = useState("");

  const [chapters, setChapters] = useState([]);

  const navigate = useNavigate();

  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const fetchCourseSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              search: searchQuery, // Send search query as a query parameter
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
        const subcategoryResponse1 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              search: searchQuery, // Send search query as a query parameter
            },
          }
        );
        setPdfs(subcategoryResponse1.data.data);
        setTotalPages(subcategoryResponse1.data.pagination.totalPages);
        setTotalItems(subcategoryResponse1.data.pagination.totalItems);
        console.log("filtered units:", subcategoryResponse1.data.data);
      }

      if (selectedSubCategory) {
        const subcategoryResponse4 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/getcourseby/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCourses(subcategoryResponse4.data.data);
        console.log("get courses :", subcategoryResponse4.data.data);

        const subcategoryResponse2 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              search: searchQuery, // Send search query as a query parameter
            },
          }
        );
        setPdfs(subcategoryResponse2.data.data);
        setTotalPages(subcategoryResponse2.data.pagination.totalPages);
        setTotalItems(subcategoryResponse2.data.pagination.totalItems);
        console.log("filtered units:", subcategoryResponse2.data.data);
      }

      if (selectedCourses) {
        const subcategoryChapterResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chapters/bycourse/${selectedCourses}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setChapters(subcategoryChapterResponse.data.data);
        console.log("get courses :", subcategoryChapterResponse.data.data);

        const subcategoryResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}/${selectedSubCategory}/${selectedCourses}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              search: searchQuery, // Send search query as a query parameter
            },
          }
        );
        setPdfs(subcategoryResponse9.data.data);
        setTotalPages(subcategoryResponse9.data.pagination.totalPages);
        setTotalItems(subcategoryResponse9.data.pagination.totalItems);
        console.log(
          "filtered units with course:",
          subcategoryResponse9.data.data
        );
      }

      if (selectedChapters) {
        const subcategorychapterResponse9 = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/unitslist/${selectedCategory}/${selectedSubCategory}/${selectedCourses}/${selectedChapters}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              search: searchQuery, // Send search query as a query parameter
            },
          }
        );
        setPdfs(subcategorychapterResponse9.data.data);
        setTotalPages(subcategorychapterResponse9.data.pagination.totalPages);
        setTotalItems(subcategorychapterResponse9.data.pagination.totalItems);
        console.log(
          "filtered units with chapters:",
          subcategorychapterResponse9.data.data
        );
      }
    } catch (err) {
      setError("Failed to fetch chapters");
    }
  };

  const fetchPdfs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/list/unitslist`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            category,
            subcategory,
            search: searchQuery,
          },
        }
      );
      setPdfs(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching units");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPdfs();
    fetchCourseCategories();
    fetchCourseSubCategories();
  }, [
    currentPage,
    itemsPerPage,
    category,
    subcategory,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedCourses,
    selectedChapters,
  ]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    setPage(0);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubCategory("");
    setSelectedCourse("");
    setSelectedChapters("");
    setCurrentPage(0);
  };

  const handleSubCategoryChange = (subCatId) => {
    setSelectedSubCategory(subCatId);
    setSelectedCourse("");
    setSelectedChapters("");
    setCurrentPage(0);
  };

  const handleCourseChange = (courseId) => {
    setSelectedCourse(courseId);
    setSelectedChapters("");
    setCurrentPage(0);
  };

  const handleChapterChange = (chapterId) => {
    setSelectedChapters(chapterId);
    setCurrentPage(0);
  };

  const handleDeleteConfirmation = (unit) => {
    setUnitToDelete(unit);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    if (!unitToDelete) return;
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/list/deleteunit/${unitToDelete._id}`
      );
      setOpenDialog(false);
      fetchPdfs();
    } catch (err) {
      setError("Failed to delete Unit");
      console.error("Error while deleting unit:", err.response || err);
    }
  };

  const handleView = (id, coursesname, chaptername, unitname) => {
    navigate(`/unitinterface/${id}`, {
      state: { coursesname, chaptername, unitname },
    });
  };

  const handleEdit = (id, coursesname, chaptername, unitname) => {
    const url = `/editunit/${id}/${encodeURIComponent(
      coursesname
    )}/${encodeURIComponent(chaptername)}/${encodeURIComponent(unitname)}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleEditIndex = async (id, indexVal) => {
    try {
      if (indexVal !== null && indexVal !== "") {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/unitupload/index/${id}`,
          {
            position: indexVal,
          },
          {
            headers: {
              // "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
          }
        );
      }

      setPdfs((prevPdfs) =>
        prevPdfs.map((pdf) =>
          pdf._id === id ? { ...pdf, position: indexVal } : pdf
        )
      );
      // Optionally, handle success message or any additional actions here
      // console.log("Data saved successfully:", response.data);
    } catch (err) {
      // Handle error state
      console.error(err.message || "Failed to upload file");
    } finally {
      // setGalleryLoading(false); // End loading state regardless of success or failure
    }
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Unit List
        </Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Autocomplete for Search */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          {/* Category Filter */}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  courseCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                } // Ensure value is null if no category is selected
                onChange={(event, newValue) => {
                  // If a new category is selected, update the selectedCategory state with its _id
                  handleCategoryChange(newValue ? newValue._id : ""); // Pass the category _id or an empty string if no selection
                }}
                options={courseCategories} // The list of options
                getOptionLabel={(option) => option.courses_name} // This will display the course name in the dropdown
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                } // Comparison function to check equality
                renderInput={(params) => (
                  <TextField {...params} label="Course Category" size="small" />
                )}
                disableClearable={false} // Optionally make the input clearable
                getOptionDisabled={(option) => !option.courses_name} // Optionally disable empty categories
              />
            </FormControl>
          </Grid>

          {/* Sub Category Filter */}

          {selectedCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courseSubCategories.find(
                      (subCategory) => subCategory._id === selectedSubCategory
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    handleSubCategoryChange(newValue ? newValue._id : "")
                  }
                  options={courseSubCategories}
                  getOptionLabel={(option) => option.courses_sub_category_name}
                  isOptionEqualToValue={(option, value) => option._id === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Subcategory"
                      size="small"
                    />
                  )}
                  sortComparator={(a, b) => {
                    const nameA = a.courses_sub_category_name;
                    const nameB = b.courses_sub_category_name;

                    // Extract numeric part and rest of the string
                    const numberA = parseInt(
                      nameA.match(/^(\d+)/)?.[0] || "0",
                      10
                    );
                    const numberB = parseInt(
                      nameB.match(/^(\d+)/)?.[0] || "0",
                      10
                    );

                    // Compare numbers first, then fallback to alphabetical comparison
                    if (numberA !== numberB) {
                      return numberA - numberB;
                    }
                    return nameA.localeCompare(nameB); // If numbers are equal, sort alphabetically
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.courses_sub_category_name}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
          )}

          {/* Course Filter */}
          {selectedSubCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courses.find((course) => course._id === selectedCourses) ||
                    null
                  }
                  onChange={(event, newValue) =>
                    handleCourseChange(newValue ? newValue._id : "")
                  }
                  options={courses}
                  getOptionLabel={(option) => option.courses_name}
                  isOptionEqualToValue={(option, value) => option._id === value}
                  renderInput={(params) => (
                    <TextField {...params} label="Course" size="small" />
                  )}
                  sortComparator={(a, b) => {
                    const nameA = a.courses_name;
                    const nameB = b.courses_name;

                    // Extract numeric part and rest of the string
                    const numberA = parseInt(
                      nameA.match(/^(\d+)/)?.[0] || "0",
                      10
                    );
                    const numberB = parseInt(
                      nameB.match(/^(\d+)/)?.[0] || "0",
                      10
                    );

                    // Compare numbers first, then fallback to alphabetical comparison
                    if (numberA !== numberB) {
                      return numberA - numberB;
                    }
                    return nameA.localeCompare(nameB); // If numbers are equal, sort alphabetically
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.courses_name}
                    </li>
                  )}
                  noOptionsText="No courses available"
                />
              </FormControl>
            </Grid>
          )}

          {/* Chapters Filter */}
         {selectedCourses &&
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  chapters.find(
                    (chapter) => chapter._id === selectedChapters
                  ) || null
                }
                onChange={(event, newValue) => {
                  // Handle change when a course is selected, passing the _id of the course
                  handleChapterChange(newValue ? newValue._id : ""); // Pass the selected course _id or empty string if no selection
                }}
                options={chapters}
                getOptionLabel={(option) => option.chapter_name}
                isOptionEqualToValue={(option, value) => option._id === value}
                renderInput={(params) => (
                  <TextField {...params} label="Chapters" size="small" />
                )}
                noOptionsText="No chapters available"
                // disableClearable
              />
            </FormControl>
          </Grid>
          }
        </Grid>
      </Box>

      {/* Table, Pagination, and Dialogs */}
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20px" }}> No.</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell> Name</TableCell>
                  <TableCell>Course Category</TableCell>
                  <TableCell>Course Subcategory</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Chapter Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pdfs.length > 0 ? (
                  pdfs.map((pdf, index) => (
                    <TableRow key={pdf._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={pdf.position}
                          onChange={(e) =>
                            handleEditIndex(pdf._id, e.target.value)
                          }
                          multiline
                          fullWidth
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>{pdf.unit_name || "N/A"}</TableCell>
                      <TableCell>
                        {pdf.course_category?.courses_name || "N/A"}
                      </TableCell>
                      <TableCell>
                        {pdf.course_sub_category?.courses_sub_category_name ||
                          "N/A"}
                      </TableCell>
                      <TableCell>{pdf.course?.courses_name || "N/A"}</TableCell>
                      <TableCell>
                        {pdf.chapterData?.chapter_name || "N/A"}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() =>
                            handleView(
                              pdf._id,
                              pdf.course?.courses_name,
                              pdf.chapterData?.chapter_name,
                              pdf.unit_name
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteConfirmation(pdf)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleEdit(
                              pdf._id,
                              pdf.course?.courses_name,
                              pdf.chapterData?.chapter_name,
                              pdf.unit_name
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No PDFs found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {totalItems > 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the unit "{unitToDelete?.unit_name}
            "? This action cannot be undone. Are you sure you want to delete the
            unit "{unitToDelete?.unit_name}
            "? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PdfList;

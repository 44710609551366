import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  Container,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/system";
import { getToken } from "../../utils/AuthUtils";

const ListEventsPage = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get location object

  // Fetch events from the backend
  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/event`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          {
            params: {
              page: page + 1 , // API expects 1-based page
              limit: rowsPerPage,
              search: searchTerm, // Pass the search term
            },
          }
        );
        setEvents(response.data.data);
        setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      }
    } catch (err) {
      setError("Error fetching events");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
    // Check if there's a message passed from the EditEventPage
    if (location.state && location.state.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setOpenSnackbar(true);
    }
  }, [location.state]); // Run when location.state changes (after redirect)

  const handleEditEvent = (id) => {
    navigate(`/events/edit/${id}`);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Toggle the event's status
  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle logic
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/event/${id}/status_event`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Event status updated successfully");
        setOpenSnackbar(true);
        fetchEvents(); // Refresh the events list
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteEvent = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/event/delete/${deleteId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Event deleted successfully");
        setOpenSnackbar(true);
        fetchEvents(); // Refresh the list after deletion
      }
    } catch (err) {
      setError("Error deleting event");
      console.error(err);
    } finally {
      handleDeleteClose();
    }
  };

  const toggleExpand = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEvents = events.filter(
    (event) =>
      event.event_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.event_coordinator
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      event.event_description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Events</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/events/add")}
          sx={{backgroundColor: "#e0e0e0"}}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20px" }}> No.</TableCell>
                <TableCell> Title</TableCell>
                <TableCell> Coordinator</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event, index) => (
                  <TableRow key={event._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{event.event_title}</TableCell>
                    <TableCell>{event.event_coordinator}</TableCell>
                    <TableCell>
                      {event.event_image && (
                        <img
                          src={event.event_image}
                          alt={event.event_title}
                          style={{ width: "100px", height: "auto" }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {expandedEventId === event._id ? (
                        <Typography>
                          {event.event_description}
                          <Button
                            onClick={() => toggleExpand(event._id)}
                            size="small"
                          >
                            Show Less
                          </Button>
                        </Typography>
                      ) : (
                        <Typography>
                          {event.event_description.length > 100
                            ? `${event.event_description.slice(0, 100)}...`
                            : event.event_description}
                          {event.event_description.length > 100 && (
                            <Button
                              onClick={() => toggleExpand(event._id)}
                              size="small"
                            >
                              Read More
                            </Button>
                          )}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={event.status === 1}
                        onChange={() =>
                          handleToggleStatus(event._id, event.status)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton onClick={() => handleEditEvent(event._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteOpen(event._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No events found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this event?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteEvent}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ListEventsPage;

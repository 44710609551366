import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Switch,
  Container,
  Snackbar,
  Alert,
  MenuItem,
  InputAdornment,
  Grid,
  FormControl,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import SearchIcon from "@mui/icons-material/Search";

const CourseListMain = () => {
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [expandedCourseId, setExpandedCourseId] = useState(null); // Track expanded course
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const location = useLocation(); // Get location
  const [category, setCategory] = useState("" ? "" : null);
  const [subcategory, setSubcategory] = useState("");
  const [categories, setCategories] = useState([]); // List of categories fetched from backend
  const [subcategories, setSubcategories] = useState([]); // List of subcategories

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoriesAndSubcategories = async () => {
      try {
        const token = getToken();
        if (token) {
          const categoryResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setCategories(categoryResponse.data.data);

          if (category && category !== "") {
            // Fetch subcategories based on selected category
            const subcategoryResponse = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${category}`,
              {
                // params: { categoryId: category },
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setSubcategories(subcategoryResponse.data.data);
          }
        }
      } catch (error) {
        console.error("Error fetching categories or subcategories:", error);
      }
    };

    fetchCategoriesAndSubcategories();
  }, [category]); // Fetch subcategories whenever category is changed

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/department`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setDepartments(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses`,
            {
              params: {
                page: currentPage + 1,
                limit: pageSize,
                searchTerm,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourses(response.data.data);
          setTotalItems(response.data.pagination.totalItems);
        }
      } catch (err) {
        console.error("Error fetching courses:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartments();
    fetchCourses();
  }, [currentPage, pageSize, searchTerm, category, subcategory]);

  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);
      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (snackbarMessage) {
      setOpenSnackbar(true);
    }
  }, [snackbarMessage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/courses/${id}`,
          { status: newStatus },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourses((prevCourses) =>
          prevCourses.map((course) =>
            course._id === id ? { ...course, status: newStatus } : course
          )
        );
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find((dep) => dep._id === departmentId);
    return department ? department.department_name : "Unknown";
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCategory(""); // Reset category
    setSubcategory(""); // Reset subcategory
    setPage(0); // Reset to first page when searching
  };

  const handleAddCourse = () => {
    navigate("/courses/add");
  };

  const handleEditCourse = (id) => {
    navigate(`/courses/edit/${id}`);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/courses/delete/${courseToDelete}`,
            {}, // Pass an empty object for the payload if there's no data to send in the body
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("course deleted successfully");
          setOpenSnackbar(true);
          setCourseToDelete(null);
          setIsDialogOpen(false);
          // Refresh the list after deletion
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses`,
            {
              params: {
                page: page + 1,
                limit: rowsPerPage,
                searchTerm,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCourses(response.data.data);
          setTotalItems(response.data.pagination.totalItems);
        }
      } catch (err) {
        console.error("Error deleting course:", err);
      }
    }
  };

  const openDeleteDialog = (id) => {
    setCourseToDelete(id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const toggleExpand = (id) => {
    setExpandedCourseId(expandedCourseId === id ? null : id);
  };

  // Filtered Courses based on search, category and subcategory
  const filteredEvents = courses
    .filter(
      (course) =>
        course.courses_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.course_details
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        getDepartmentName(course.department_id)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        course.no_of_chapters.toString().includes(searchTerm) ||
        course.course_category_id.courses_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        course.course_subcategory_id.courses_sub_category_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .filter(
      (course) =>
        (!category || course.course_category_id._id === category) &&
        (!subcategory || course.course_subcategory_id._id === subcategory)
    );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Courses</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          {/* Category Dropdown */}

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  categories.find(
                    (categoryItem) => categoryItem._id === category
                  ) || null
                } // Ensure value is null for Autocomplete instead of empty string
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue._id : null); // Store full category object (or null if no selection)
                  setSubcategory(""); // Reset subcategory when category changes
                  // Make API call here with the selected category's _id
                }}
                options={categories}
                getOptionLabel={(option) => option.courses_name || ""} // Display courses_name as the label
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                } // Ensure the correct option is selected
                // disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    size="small"
                    variant="outlined"
                  />
                )}
                // Optionally, add sorting for categories if required
                sortBy={(a, b) => a.courses_name.localeCompare(b.courses_name)}
                sx={{
                  width: "100%", // To make sure it stretches to fill the available width
                }}
              />
            </FormControl>
          </Grid>

          {/* Subcategory Dropdown */}
          {category && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    subcategories.find(
                      (subCategoryItem) => subCategoryItem._id === subcategory
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSubcategory(newValue ? newValue._id : null); // Set subcategory by its ID
                    setPage(0); // Reset to the first page when subcategory changes
                  }}
                  options={subcategories}
                  getOptionLabel={(option) =>
                    option.courses_sub_category_name || ""
                  } // Display the subcategory name
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  } // Compare by subcategory ID
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subcategory"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  // disableClearable
                  sx={{ width: "100%" }} // Ensure it takes full width
                  sortBy={(a, b) =>
                    a.courses_sub_category_name.localeCompare(
                      b.courses_sub_category_name
                    )
                  } // Optional: Sorting the options A-Z
                />
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddCourse}
              sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> No.</TableCell>
                <TableCell> Name</TableCell>
                <TableCell> Category </TableCell>
                <TableCell> Subcategory </TableCell>
                <TableCell> Grade</TableCell>
                <TableCell> Thumbnail</TableCell>
                {/* <TableCell> Image</TableCell> */}
                {/* <TableCell>Details</TableCell> */}
                <TableCell>No. Of Chapters</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((course, index) => (
                  <TableRow key={course._id}>
                    <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                    <TableCell>{course.courses_name}</TableCell>
                    <TableCell>
                      {course.course_category_id?.courses_name}
                    </TableCell>
                    <TableCell>
                      {course.course_subcategory_id?.courses_sub_category_name}
                    </TableCell>
                    <TableCell>{course.grade_id?.gradename}</TableCell>
                    <TableCell>
                      {course.course_thumbnail ? (
                        <img
                          src={course.course_thumbnail}
                          alt="Course Thumbnail"
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        "No image"
                      )}
                    </TableCell>
                    {/* <TableCell>
                      {course.course_image ? (
                        <img
                          src={course.course_image}
                          alt="Course Image"
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        "No Photo"
                      )}
                    </TableCell> */}
                    {/* <TableCell>
                      <Typography>
                        {expandedCourseId === course._id ? (
                          <span>
                            {course.course_details}
                            <Button
                              onClick={() => toggleExpand(course._id)}
                              size="small"
                            >
                              Show Less
                            </Button>
                          </span>
                        ) : (
                          <span>
                            {course.course_details.length > 100
                              ? `${course.course_details.slice(0, 100)}...`
                              : course.course_details}
                            {course.course_details.length > 100 && (
                              <Button
                                onClick={() => toggleExpand(course._id)}
                                size="small"
                              >
                                Read More
                              </Button>
                            )}
                          </span>
                        )}
                      </Typography>
                    </TableCell> */}
                    <TableCell>{course.no_of_chapters}</TableCell>
                    <TableCell>
                      {getDepartmentName(course.department_id)}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={course.status === 1}
                        onChange={() =>
                          handleToggleStatus(course._id, course.status)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditCourse(course._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => openDeleteDialog(course._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No courses found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {totalItems > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100,200]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      )}

      {/* Confirmation Dialog */}
      <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this course?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button color="error" onClick={handleDeleteCourse}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CourseListMain;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Box,
  TablePagination,
  Switch,
  TextField,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  InputAdornment,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getToken } from "../../utils/AuthUtils";
import axios from "axios";
// import SearchIcon from "@mui/icons-material/Search";

const ChaptersTable = () => {
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteChapterId, setDeleteChapterId] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const location = useLocation(); // Get location
  const navigate = useNavigate();

  useEffect(() => {
    const message = sessionStorage.getItem("ChapterSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      sessionStorage.removeItem("ChapterSuccessMessage"); // Remove the message after showing
    }
    fetchChapters();
    fetchCourseCategories();
    fetchCourseSubCategories();
    fetchCourses();
  }, [
    currentPage,
    pageSize,
    searchTerm,
    selectedCategory,
    selectedSubCategory,
    selectedCourse,
  ]);

  const handleClick = () => {
    navigate("/chapters_add");
  };

  const fetchChapters = async () => {
    try {
      const token = getToken();
      if (token) {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/chapters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: currentPage + 1, // Convert to 1-based index
              limit: pageSize,
              search: searchTerm,
              courseCategory: selectedCategory,
              courseSubCategory: selectedSubCategory,
              course: selectedCourse,
            },
          }
        );
        const data = response.data;
        if (data?.data) {
          setChapters(data.data);
          setTotalItems(data.pagination.totalItems);
          setError(null);
        } else {
          setError("No chapters found");
        }
      }
    } catch (err) {
      setError("Failed to fetch chapters");
    } finally {
      setLoading(false);
    }
  };

  const fetchCourseCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category/nopagenation`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    try {
      if (!selectedCategory) {
        return;
      }
      const token = getToken();

      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub/getsubcategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourseSubCategories(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch course subcategories");
    }
  };

  useEffect(() => {
    fetchCourseSubCategories();
  }, [selectedCategory]);

  const fetchCourses = async () => {
    try {
      if (!selectedSubCategory) {
        return;
      }
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/courses/getcourseby/${selectedSubCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourses(response.data.data || []);
      }
    } catch (err) {
      setError("Failed to fetch courses");
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [selectedSubCategory]);

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateChapterStatus(id, newStatus);
  };

  const updateChapterStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/chapters/${id}/status_chapter`,
          { status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSnackbarMessage("Chapter status updated successfully");
        setOpenSnackbar(true);
        fetchChapters();
      }
    } catch (err) {
      setError("Failed to update chapter status");
    }
  };

  const handleOpenDialog = (id) => {
    setDeleteChapterId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteChapterId(null);
  };

  const handleDelete = async () => {
    try {
      const token = getToken();
      if (!token) {
        setError("No authorization token found");
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/chapters/delete/${deleteChapterId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.status === 200) {
        throw new Error("Failed to delete chapter");
      }

      setSnackbarMessage("Chapter deleted successfully");
      setOpenSnackbar(true);
      fetchChapters();
    } catch (err) {
      console.error(err);
      setError("Failed to delete chapter");
    } finally {
      handleCloseDialog();
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    setSelectedCourse(""); // Reset course when category changes
    setCurrentPage(0); // Reset to the first page
    console.log("Selected Category: ", categoryId); // Debugging line
  };

  const handleSubCategoryChange = (subCatId) => {
    setSelectedSubCategory(subCatId);
    setSelectedCourse(""); // Reset course when subcategory changes
    setCurrentPage(0);
  };

  const handleCourseChange = (courseId) => {
    setSelectedCourse(courseId);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderDescription = (chapter) => {
    const isExpanded = expandedDescriptions[chapter._id];
    const shortDescription =
      chapter.chapter_description.substring(0, 100) +
      (chapter.chapter_description.length > 100 ? "..." : "");

    return (
      <div>
        {isExpanded ? chapter.chapter_description : shortDescription}
        {chapter.chapter_description.length > 100 && (
          <Button onClick={() => toggleDescription(chapter._id)} size="small">
            {isExpanded ? "Read Less" : "Read More"}
          </Button>
        )}
      </div>
    );
  };

  const getCategoryName = (id) => {
    const category = courseCategories.find((cat) => cat._id === id);
    return category ? category.courses_name : "Unknown";
  };

  const getSubCategoryName = (id) => {
    const subCategory = courseSubCategories.find((sub) => sub._id === id);
    return subCategory ? subCategory.courses_sub_category_name : "Unknown";
  };

  const getCourseName = (id) => {
    const course = courses.find((c) => c._id === id);
    return course ? course.courses_name : "Unknown";
  };
  const handleEditPosition = async (id, position) => {
    try {
      if (position !== null && position !== "") {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/chapters/position/${id}`,
          {
            position: position,
          },
          {
            headers: {
              // "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token_key"),
            },
          }
        );
      }

      setChapters((prevChapter) =>
        prevChapter.map((chapter) =>
          chapter._id === id ? { ...chapter, position: position } : chapter
        )
      );
      // Optionally, handle success message or any additional actions here
      // console.log("Data saved successfully:", response.data);
    } catch (err) {
      // Handle error state
      console.error(err.message || "Failed to upload file");
    } finally {
      // setGalleryLoading(false); // End loading state regardless of success or failure
    }
  };

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h4">Chapters</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  courseCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                } // Set selected category or null if none selected
                onChange={(event, newValue) => {
                  // If a new category is selected, update the selectedCategory state with its _id
                  handleCategoryChange(newValue ? newValue._id : ""); // Pass the category _id or an empty string if no selection
                }}
                options={courseCategories}
                getOptionLabel={(option) => option.courses_name || ""} // Display courses_name as the label for each option
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                } // Compare option and value by _id to determine equality
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Course Category"
                    size="small"
                    variant="outlined"
                  />
                )}
                // disableClearable // Disable the clear button for better control
                sx={{ width: "100%" }} // Ensure the autocomplete takes full width
                sortBy={(a, b) => a.courses_name.localeCompare(b.courses_name)} // Optional: Sorting the options A-Z
              />
            </FormControl>
          </Grid>

          {selectedCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courseSubCategories.find(
                      (subCategory) => subCategory._id === selectedSubCategory
                    ) || null
                  } // Set selected subcategory or null if none selected
                  onChange={(event, newValue) => {
                    // If a new subcategory is selected, update the selectedSubCategory state with its _id
                    handleSubCategoryChange(newValue ? newValue._id : ""); // Pass the subcategory _id or an empty string if no selection
                  }}
                  options={courseSubCategories}
                  getOptionLabel={(option) =>
                    option.courses_sub_category_name || ""
                  } // Display courses_sub_category_name as the label for each option
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  } // Compare option and value by _id to determine equality
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course Subcategory"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  // disableClearable // Disable the clear button for better control
                  sx={{ width: "100%" }} // Ensure the autocomplete takes full width
                  sortBy={(a, b) =>
                    a.courses_sub_category_name.localeCompare(
                      b.courses_sub_category_name
                    )
                  } // Optional: Sorting the options A-Z
                />
              </FormControl>
            </Grid>
          )}

          {selectedSubCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    courses.find((course) => course._id === selectedCourse) ||
                    null
                  } // Set selected course or null if no selection
                  onChange={(event, newValue) => {
                    // Handle change when a course is selected, passing the _id of the course
                    handleCourseChange(newValue ? newValue._id : ""); // Pass the selected course _id or empty string if no selection
                  }}
                  options={courses}
                  getOptionLabel={(option) => option.courses_name || ""} // Display the course name
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  } // Compare option and value by _id to determine equality
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  // disableClearable // Disable the clear button for better control
                  sx={{ width: "100%" }} // Ensure autocomplete takes full width
                  sortBy={(a, b) =>
                    a.courses_name.localeCompare(b.courses_name)
                  } // Optional: Sorting the options A-Z
                />
              </FormControl>
            </Grid>
          )}
          {/* Create Button */}
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClick}
              sx={{ mx: 2, backgroundColor: "#e0e0e0" }}>
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Order</TableCell>
              <TableCell> Name</TableCell>
              {/* <TableCell> Description</TableCell> */}
              <TableCell>Course Category</TableCell>
              <TableCell>Course Subcategory</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : chapters.length > 0 ? (
              chapters.map((chapter, index) => (
                <TableRow key={chapter._id}>
                  <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={chapter.position}
                      onChange={(e) =>
                        handleEditPosition(chapter._id, e.target.value)
                      }
                      multiline
                      fullWidth
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{chapter.chapter_name}</TableCell>
                  {/* <TableCell>{renderDescription(chapter)}</TableCell> */}
                  <TableCell>
                    {getCategoryName(chapter.course_category_id)}
                  </TableCell>
                  <TableCell>
                    {chapter?.course_sub_category_id?.courses_sub_category_name}
                  </TableCell>
                  <TableCell>{chapter.course_id.courses_name}</TableCell>
                  <TableCell>{new Date(chapter?.createdAt).toLocaleDateString()} </TableCell>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    <Switch
                      checked={chapter.status}
                      onChange={() =>
                        handleStatusToggle(chapter._id, chapter.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <IconButton
                        color="primary"
                        component={Link}
                        to={`/edit_chapter/${chapter._id}`}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDialog(chapter._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No chapters available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this chapter? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ChaptersTable;

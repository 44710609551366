import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  InputAdornment,
  MenuItem,
  Grid,
  FormControl,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subCategoryFilter, setSubCategoryFilter] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const navigate = useNavigate();
  const location = useLocation(); // Get location
  const [productCategories, setProductCategories] = useState([]);
  const [productSubCategories, setproductSubCategories] = useState([]);
  useEffect(() => {
    // Check for snackbar message in the location state and set it
    if (location.state?.snackbarMessage) {
      setSnackbarMessage(location.state.snackbarMessage);
      setSnackbarOpen(true);

      // Clear the location state snackbar message after it has been set
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/product/category/nopagination`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProductCategories(response.data.data || []);
        }
      } catch (err) {
        console.error("Failed to fetch categories", err);
      }
    };

    fetchCategories();
  }, []);

  const fetchSubCategories = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        // Fetch subcategories
        const subCategoriesResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/category_sub/category/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setproductSubCategories(subCategoriesResponse.data.data || []);

        // Fetch products by category
        const productsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/bycategory/${selectedCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProducts(productsResponse.data.data || []);
      }
    } catch (err) {
      console.error("Failed to fetch subcategories or products", err);
    }
  };

  const fetchproductsBySubCat = async () => {
    if (!selectedSubCategory) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        // Fetch products by category
        const productsResponses = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product/bycategory/${selectedCategory}/${selectedSubCategory}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProducts(productsResponses.data.data || []);
      }
    } catch (err) {
      console.error("Failed to fetch  products by sub cat", err);
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, [selectedCategory]);

  useEffect(() => {
    fetchproductsBySubCat();
  }, [selectedSubCategory]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchProducts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, page, rowsPerPage, categoryFilter]);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/product`,
          {
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
              category: selectedCategory,
              subCategory: selectedSubCategory,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProducts(response.data.data);
        setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
      }
    } catch (err) {
      setError("Error fetching products");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/product/${id}`,
          {
            status: status === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchProducts();

        // Show Snackbar message
        setSnackbarMessage("Status updated successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleAddProduct = () => {
    navigate("/products/add");
  };

  const handleEditProduct = (id) => {
    navigate(`/products/edit/${id}`);
  };

  const handleOpenDialog = (id) => {
    setProductToDelete(id);
    setDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setProductToDelete(null);
    setDialogOpen(false);
  };

  const handleDeleteProduct = async () => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/product/delete/${productToDelete}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchProducts(); // Refresh the list after deletion
        // Show Snackbar message
        setSnackbarMessage("Product Deleted successfully");
        setSnackbarOpen(true);
      }
    } catch (err) {
      setError("Error deleting product"); // Show Snackbar message
      setSnackbarMessage("Error Deleting Product");
      setSnackbarOpen(true);
      console.error(err);
    } finally {
      handleCloseDialog();
    }
  };
  // Handle category Change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
    // setCurrentPage(0); // Reset to the first page when the category changes
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    // setCurrentPage(0); // Reset to the first page when the subcategory changes
  };
  const getCategoryName = (id) => {
    const category = categories.find((cat) => cat._id === id);
    return category ? category.product_category_name : "Unknown";
  };

  const getSubCategoryName = (id) => {
    const subCategory = subCategories.find((subCat) => subCat._id === id);
    return subCategory ? subCategory.product_sub_category_name : "Unknown";
  };

  const filteredproducts = products.filter(
    (product) =>
      (product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getCategoryName(product.product_category_id)
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        getSubCategoryName(product.product_sub_category_name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      (categoryFilter
        ? product.product_category_id === categoryFilter
        : true) &&
      (subCategoryFilter
        ? product.product_sub_category_name === subCategoryFilter
        : true)
  );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Products</Typography>
        <TextField
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        // display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2} mb={2} alignItems="flex-start">
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  productCategories.find(
                    (category) => category._id === selectedCategory
                  ) || null
                }
                onChange={(event, newValue) => {
                  setSelectedCategory(newValue ? newValue._id : ""); // Set selected category
                  setSelectedSubCategory("");
                  fetchProducts();
                  setPage(0);
                }}
                options={productCategories}
                getOptionLabel={(option) => option.product_category_name || ""}
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                } // Compare based on category _id
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    size="small"
                  />
                )}
                getOptionDisabled={(option) => !option.product_category_name} // Optionally disable empty categories
                renderOption={(props, option) => (
                  <li {...props}>{option.product_category_name}</li>
                )}
              />
            </FormControl>
          </Grid>
          {selectedCategory && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                value={
                  selectedSubCategory
                    ? productSubCategories.find(
                        (subCategory) => subCategory._id === selectedSubCategory
                      )
                    : null
                }
                onChange={(event, newValue) => {
                  setSelectedSubCategory(newValue?._id || ""); // Update selected subcategory
                }}
                options={productSubCategories}
                getOptionLabel={(option) =>
                  option.product_sub_category_name || ""
                }
                isOptionEqualToValue={(option, value) =>
                  option._id === value?._id
                } // Compare based on subcategory _id
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Subcategory"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                getOptionDisabled={(option) =>
                  !option.product_sub_category_name
                } // Optionally disable empty subcategories
                renderOption={(props, option) => (
                  <li {...props}>{option.product_sub_category_name}</li>
                )}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddProduct}
              // disabled={loading}
              sx={{ backgroundColor: "#e0e0e0", height: "40px" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell> Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Subcategory</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredproducts.length > 0 ? (
                filteredproducts.map((product, index) => (
                  <TableRow key={product._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{product?.product_name}</TableCell>
                    <TableCell>
                      {product?.product_category_id?.product_category_name}
                    </TableCell>
                    <TableCell>
                      {
                        product?.product_sub_category_name
                          ?.product_sub_category_name
                      }
                    </TableCell>
                    <TableCell>
                      {new Date(product?.createdAt).toLocaleDateString()}{" "}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      <Switch
                        checked={product.status === 1}
                        onChange={() =>
                          handleToggleStatus(product._id, product.status)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center" // Optional, aligns buttons horizontally in the center
                      >
                        <IconButton
                          onClick={() => handleEditProduct(product._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDialog(product._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    {"No Products available."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Product?"}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProduct} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert severity="success" onClose={() => setSnackbarOpen(false)}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default ProductList;

import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";

function AddCountry({ open, onClose, onAdd }) {
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCountryNameChange = (e) => {
    setCountryName(e.target.value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleCountrySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = getToken();
      if (token) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/country`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              country_name: countryName,
              country_code: countryCode,
            }),
          }
        );
        setCountryCode("");
        setCountryName("");
        if (response.ok) {
          onAdd(); // Notify parent to refresh data
          onClose(); // Close the modal
        } else {
          setSnackbarMessage("Failed to add country");
        }
      }
    } catch (error) {
      setSnackbarMessage("Error occurred");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        <form onSubmit={handleCountrySubmit}>
          <TextField
            label="Country Name"
            value={countryName}
            onChange={handleCountryNameChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Country Code"
            value={countryCode}
            onChange={handleCountryCodeChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="center"
            mt={2} // Optional, aligns buttons horizontally in the center
          >
            <Box display="flex" justifyContent="center">
            <Button onClick={onClose} color="primary"
            sx={{ marginLeft:2}}
            >
              Cancel
            </Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button type="submit" variant="contained" color="primary" sx={{backgroundColor:"#e0e0e0" }}>
                  Create
                </Button>
              )}
            </Box>
           
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={
              snackbarMessage === "Country added successfully"
                ? "success"
                : "error"
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
}

export default AddCountry;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem } from '@mui/material';
import { getToken } from '../utils/AuthUtils';

const AddPlace = ({ isOpen, onClose, onAdd }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [placeName, setPlaceName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedTown, setSelectedTown] = useState('');
  const [status, setStatus] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Error states for each field
  const [error, setError] = useState({
    placeName: '',
    country: '',
    state: '',
    district: '',
    town: '',
  });

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
      setSelectedState('');
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    } else {
      setDistricts([]);
      setSelectedDistrict('');
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      fetchTownsByDistrict(selectedDistrict);
    } else {
      setTowns([]);
      setSelectedTown('');
    }
  }, [selectedDistrict]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch countries:', err.response?.data?.message || err.message);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
          params: { countryId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStates(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch states:', err.response?.data?.message || err.message);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state`, {
          params: { stateId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch districts:', err.response?.data?.message || err.message);
    }
  };

  const fetchTownsByDistrict = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town/by-district`, {
          params: { districtId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTowns(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch towns:', err.response?.data?.message || err.message);
    }
  };

  const handleAddPlace = async () => {
    // Reset error states before validation
    setError({
      placeName: '',
      country: '',
      state: '',
      district: '',
      town: '',
    });

    let isValid = true;

    // Validate each field
    if (!placeName.trim()) {
      setError((prev) => ({ ...prev, placeName: 'Place name is required.' }));
      isValid = false;
    }
    if (!selectedCountry) {
      setError((prev) => ({ ...prev, country: 'Country is required.' }));
      isValid = false;
    }
    if (!selectedState) {
      setError((prev) => ({ ...prev, state: 'State is required.' }));
      isValid = false;
    }
    if (!selectedDistrict) {
      setError((prev) => ({ ...prev, district: 'District is required.' }));
      isValid = false;
    }
    if (!selectedTown) {
      setError((prev) => ({ ...prev, town: 'Town is required.' }));
      isValid = false;
    }

    // If validation fails, don't proceed with the API call
    if (!isValid) {
      return;
    }

    try {
      setSaveLoading(true);
      const token = getToken();
      if (token) {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/place`, {
          place_name: placeName.trim(),
          country: selectedCountry,
          state: selectedState,
          district: selectedDistrict,
          town: selectedTown,
          status: true,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        onAdd(); // Refresh the list
        onClose();
        setSnackbarMessage('District added successfully');
        setOpenSnackbar(true);
      }
    } catch (err) {
      console.error('Failed to add Place:', err.response?.data?.message || err.message);
      setError((prev) => ({
        ...prev,
        placeName: `Failed to add place: ${err.response?.data?.message || err.message}`,
      }));
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          error={!!error.country}
          helperText={error.country}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="State"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          disabled={!selectedCountry}
          error={!!error.state}
          helperText={error.state}
        >
          {states.map((state) => (
            <MenuItem key={state._id} value={state._id}>
              {state.state_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="District"
          value={selectedDistrict}
          onChange={(e) => setSelectedDistrict(e.target.value)}
          disabled={!selectedState}
          error={!!error.district}
          helperText={error.district}
        >
          {districts.map((district) => (
            <MenuItem key={district._id} value={district._id}>
              {district.district_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Town"
          value={selectedTown}
          onChange={(e) => setSelectedTown(e.target.value)}
          disabled={!selectedDistrict}
          error={!!error.town}
          helperText={error.town}
        >
          {towns.map((town) => (
            <MenuItem key={town._id} value={town._id}>
              {town.Town_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Place Name"
          value={placeName}
          onChange={(e) => setPlaceName(e.target.value)}
          error={!!error.placeName}
          helperText={error.placeName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddPlace} color="primary" variant="contained" disabled={saveLoading}
            >
            {saveLoading ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlace;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Container,
  Box,
  IconButton,
  Switch,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Snackbar,
  Alert,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

const DepartmentList = () => {
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [departmentCategorySubs, setDepartmentCategorySubs] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);


  const [isModalOpen, setIsModalOpen] = useState(false);

  // Department filters
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);


  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };



  useEffect(() => {
    fetchDepartmentCategories(); // fetch categories on mount
  }, []);

  useEffect(() => {
    fetchDepartments();
    fetchDepartmentCategorySubs(); // fetch subcategories on mount
  }, [currentPage, pageSize,]);

  useEffect(() => {
    // Reset departments when a new subcategory is selected
    setDepartments([]);
    setFilteredDepartments([]);
    fetchDepartments(); // Fetch departments after resetting
  }, [selectedSubcategory]); // Trigger when selectedSubcategory changes

  useEffect(() => {
    if (isModalOpen) {
      // return;
      setDepartments(departments);
    }; // Skip filtering if the modal is open
    const filtered = departments.filter((dept) => {
      const categoryName =
        departmentCategories.find(
          (cat) => cat._id === dept.department_category_id
        )?.department_category_name || "";
      const subcategoryName =
        departmentCategorySubs.find(
          (sub) => sub._id === dept.department_sub_category_id
        )?.department_sub_category_name || "";

      return (
        (dept?.department_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          subcategoryName.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (!selectedCategory ||
          dept.department_category_id === selectedCategory) &&
        (!selectedSubcategory ||
          dept.department_sub_category_id === selectedSubcategory)
      );
    });
    setFilteredDepartments(filtered);
  }, [
    searchQuery,
    departments,
    departmentCategories,
    departmentCategorySubs,
    selectedCategory,
    selectedSubcategory,
    // isModalOpen,
  ]);

  useEffect(() => {
    setPage(0); // Reset to first page when filtered departments change
  }, [filteredDepartments]);

  // Fetch Departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: currentPage + 1,
              limit: pageSize,
            },
          }
        );
        console.log("API Response:", response.data); // Log the full response to check structure
        setDepartments(response.data.data || []);

        // Set pagination data from the response
        setTotalItems(response.data.pagination.totalItems); // Total number of items

      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Department Categories
  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category/nopagination`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setDepartmentCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };

  // Fetch Department Category Subs
  const fetchDepartmentCategorySubs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/nopagnation`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setDepartmentCategorySubs(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department category subs", error);
    }
  };

  // Filter subcategories based on selected category
  useEffect(() => {
    if (selectedCategory) {
      const filtered = departmentCategorySubs.filter(
        (subcategory) => subcategory.department_category_id === selectedCategory
      );
      setFilteredSubcategories(filtered);
    } else {
      setFilteredSubcategories([]);
    }
  }, [selectedCategory, departmentCategorySubs]);

  // Handle Save or Update
  const handleSave = async () => {
    try {
      setIsAddLoading(true);
      const token = getToken();
      if (token) {
        if (isEdit) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/department/${currentDepartment._id}`,
            currentDepartment,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Department Updated successfully");
          setOpenSnackbar(true);
        } else {
          await axios.post(
            `${process.env.REACT_APP_BACKEND}/api/department`,
            currentDepartment,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Department added successfully");
          setOpenSnackbar(true);
        }
        fetchDepartments();
        handleCloseDialog();
      }
    } catch (error) {
      console.error("Error saving department", error);
    } finally {
      setIsAddLoading(false);
    }
  };

  // Delete Department
  const handleDelete = async () => {
    if (departmentToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/department/delete/${departmentToDelete._id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          fetchDepartments();
          handleCloseConfirmDialog();
          setSnackbarMessage("Department Deleted successfully");
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error("Error deleting department", error);
      }
    }
  };

  // Toggle Status
  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department/${id}`,
        {
          status: status === 1 ? 0 : 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      fetchDepartments();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Open Dialog for Add/Edit
  const handleOpenDialog = (department = {}) => {
    // setIsModalOpen(true);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setCurrentDepartment(department);
    setIsEdit(!!department._id);
    // setSelectedCategory(department.department_category_id);
    // setSelectedSubcategory(department.department_sub_category_id);
    setFilteredSubcategories(
      departmentCategorySubs.filter(
        (sub) => sub.department_category_id === department.department_category_id
      )
    );
    setOpenDialog(true);
  };
  
  

  // Close Dialog
  const handleCloseDialog = () => {
    setIsModalOpen(false);  // Close modal state
    setCurrentDepartment({});
    setOpenDialog(false);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  // Open Confirmation Dialog
  const handleOpenConfirmDialog = (department) => {
    setDepartmentToDelete(department);
    setConfirmDelete(true);
  };

  // Close Confirmation Dialog
  const handleCloseConfirmDialog = () => {
    setDepartmentToDelete(null);
    setConfirmDelete(false);
  };

  // Pagination Handlers
  // Handling page change (for pagination)
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   fetchDepartments(newPage + 1, rowsPerPage); // Fetch the new page (API is 1-indexed)
  // };

  // // Handling rows per page change
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 100));
  //   setPage(0); // Reset to first page when rows per page is changed
  //   fetchDepartments(1, parseInt(event.target.value, 100)); // Fetch the first page with new rows per page
  // };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Typography color="error">Error loading data: {error.message}</Typography>
    );

  // Calculate current page data
  const paginatedDepartments = filteredDepartments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Check if fields are valid
  const isFormValid =
    currentDepartment.department_name &&
    currentDepartment.department_category_id &&
    currentDepartment.department_sub_category_id;

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Department List
        </Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  selectedCategory
                    ? departmentCategories.find(
                        (cat) => cat._id === selectedCategory
                      )
                    : null
                }
                onChange={(e, newValue) => {
                  setSelectedCategory(newValue?._id || ""); // Reset to empty string if no category selected
                  setSelectedSubcategory(); // Reset subcategory when category changes
                }}
                options={departmentCategories}
                getOptionLabel={(option) => option.department_category_name} // Label displayed in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Ensures correct option is selected
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Department Category"
                    size="small"
                  />
                )}
                // Sort the options alphabetically by department_category_name
                sortBy={(a, b) =>
                  a.department_category_name.localeCompare(
                    b.department_category_name
                  )
                }
                // Enable clearable button
                disableClearable={false} // Ensure clear button is visible
              />
            </FormControl>
          </Grid>

          {selectedCategory && (
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    selectedSubcategory
                      ? filteredSubcategories.find(
                          (sub) => sub._id === selectedSubcategory
                        )
                      : null
                  }
                  onChange={(e, newValue) =>
                    setSelectedSubcategory(newValue ? newValue._id : "")
                  }
                  options={filteredSubcategories}
                  getOptionLabel={(option) =>
                    option.department_sub_category_name
                  } // Display the subcategory name
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  } // Compare based on _id
                  // disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department Subcategory"
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog(true)}
              sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Department Category</TableCell>
              <TableCell>Department Subcategory</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDepartments.length > 0 ? (
              paginatedDepartments.map((department, index) => (
                <TableRow key={department._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{department.department_name}</TableCell>
                  <TableCell>
                    {departmentCategories.find(
                      (cat) => cat._id === department.department_category_id
                    )?.department_category_name || "N/A"}
                  </TableCell>
                  <TableCell>
                    {departmentCategorySubs.find(
                      (sub) => sub._id === department.department_sub_category_id
                    )?.department_sub_category_name || "N/A"}
                  </TableCell>
                  <TableCell>{new Date(department?.createdAt).toLocaleDateString()} </TableCell>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    <Switch
                      checked={department.status === 1}
                      onChange={() =>
                        handleToggleStatus(department._id, department.status)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="row"
                        justifyContent="center"
                      alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      <IconButton onClick={() => handleOpenDialog(department)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleOpenConfirmDialog(department)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* pagination  */}
      {totalItems > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={pageSize}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePageSizeChange}
        />
      )}

      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {isEdit ? "Edit Department" : "Add Department"}
        </DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Department Category"
            fullWidth
            margin="normal"
            value={currentDepartment.department_category_id || ""}
            onChange={(e) => {
              setCurrentDepartment({
                ...currentDepartment,
                department_category_id: e.target.value,
              });
              setFilteredSubcategories(
                departmentCategorySubs.filter(
                  (sub) => sub.department_category_id === e.target.value
                )
              );
            }}
          >
            {departmentCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Department Subcategory"
            fullWidth
            margin="outlined"
            value={currentDepartment.department_sub_category_id || ""}
            onChange={(e) =>
              setCurrentDepartment({
                ...currentDepartment,
                department_sub_category_id: e.target.value,
              })
            }
          >
            {filteredSubcategories.map((sub) => (
              <MenuItem key={sub._id} value={sub._id}>
                {sub.department_sub_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Department Name"
            fullWidth
            margin="normal"
            value={currentDepartment.department_name || ""}
            onChange={(e) =>
              setCurrentDepartment({
                ...currentDepartment,
                department_name: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!isFormValid || isAddLoading}
          >
            {isEdit ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog for Deletion */}
      <Dialog open={confirmDelete} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this department?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DepartmentList;


import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditTown from "./EditTown";
import AddTown from "./AddTown";
import { getToken } from "../utils/AuthUtils";

function TownList() {
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
    fetchCountries();
    fetchStates();
    fetchDistricts();
  }, [
    page,
    rowsPerPage,
    searchTerm,
    selectedCountry,
    selectedState,
    selectedDistrict,
  ]);
  

  const fetchCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1,
              limit: rowsPerPage,
              searchTerm,
              country: selectedCountry,
              state: selectedState,
              district: selectedDistrict,
            },
          }
        );

        setCategories(response.data.data);
        setTotalItems(response.data.pagination.totalItems || 0);
      }
    } catch (err) {
      const errorMessage = "Error fetching Town"; // Default message
      console.log("error: ", err);
      setError(errorMessage); // Set the error state
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries");
    }
  };

  const fetchStates = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/without_pagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch states");
    }
  };

  const fetchDistricts = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/without_pagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch districts");
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddPartner = () => {
    handleCloseAddModal();
    fetchCategories(); // Refresh the list after adding
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCategoryId(null);
  };

  const handleDelete = async (categoryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/town/delete/${categoryId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setSnackbarMessage("Town deleted successfully");
        setOpenSnackbar(true);
        fetchCategories(); // Refresh the list after deletion
      }
    } catch (err) {
      setSnackbarMessage("Failed to delete town");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSelectedCategory(response.data.data);
        setSelectedCategoryId(id);
        setOpenEditModal(true);
      }
    } catch (err) {
      setSnackbarMessage("Error fetching Town details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategoryId(null);
    setSelectedCategory(null);
  };

  const getCountryName = (id) => {
    const country = countries.find((c) => c._id === id);
    return country ? country.country_name : "Unknown";
  };

  const getStateName = (id) => {
    const state = states.find((s) => s._id === id);
    return state ? state.state_name : "Unknown";
  };

  const getDistrictName = (id) => {
    const district = districts.find((d) => d._id === id);
    return district ? district.district_name : "Unknown";
  };

  const getCountryNameById = (id) => {
    const country = countries.find((country) => country._id === id);
    return country ? country.country_name : "Unknown";
  };

  const getStateNameById = (id) => {
    const state = states.find((state) => state._id === id);
    return state ? state.state_name : "Unknown";
  };

  const getDistrictNameById = (id) => {
    const district = districts.find((district) => district._id === id);
    return district ? district.district_name : "Unknown";
  };

  const filteredTowns = categories.filter((category) => {
    // Handle cases where the fields might be undefined or null.
    const townNameMatch = category.Town_name?.toLowerCase().includes(
      searchTerm.toLowerCase()
    );
    const countryNameMatch = category.country?.country_name
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const stateNameMatch = getStateNameById(category.state)
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const districtNameMatch = getDistrictNameById(category.district)
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    return (
      townNameMatch || countryNameMatch || stateNameMatch || districtNameMatch
    );
  });

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        mb={2}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Town Listing</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={selectedCountry
                  ? countries.find((country) => country._id === selectedCountry)
                  : null}
                onChange={(e, newValue) =>
                  setSelectedCountry(newValue?._id || "")
                } // Update selectedCountry with _id
                options={countries.sort((a, b) =>
                  a.country_name.localeCompare(b.country_name)
                )} // Sort countries alphabetically
                getOptionLabel={(option) => option.country_name} // Display the country name in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match the country _id with the selected value
                renderInput={(params) => (
                  <TextField {...params} label="Country" size="small" />
                )}
                // Enable clearable button to allow the user to clear the selection
                disableClearable={false} // Allow the user to clear the selection
              />
            </FormControl>
          </Grid>
          {selectedCountry &&
            <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  selectedState
                    ? states.find((state) => state._id === selectedState)
                    : null
                }
                onChange={(e, newValue) =>
                  setSelectedState(newValue?._id || "")
                } // Update the selectedState with _id
                options={states
                  .filter((state) => state.country === selectedCountry) // Filter states by selected country
                  .sort((a, b) => a.state_name.localeCompare(b.state_name))} // Sort states alphabetically
                getOptionLabel={(option) => option.state_name} // Display the state name in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match the selected state _id with the value
                renderInput={(params) => (
                  <TextField {...params} label="State" size="small" />
                )}
                // Enable clearable button to allow the user to clear the selection
                disableClearable={false} // Allow the user to clear the selection
              />
            </FormControl>
          </Grid>}
        { selectedState &&
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  selectedDistrict
                    ? districts.find(
                        (district) => district._id === selectedDistrict
                      )
                    : null
                }
                onChange={(e, newValue) =>
                  setSelectedDistrict(newValue?._id || "")
                } // Handle selection and reset to empty if no value selected
                options={districts
                  .filter((district) => district.state === selectedState) // Filter districts by selected state
                  .sort((a, b) =>
                    a.district_name.localeCompare(b.district_name, "en", {
                      sensitivity: "base",
                    })
                  )} // Sort districts alphabetically
                getOptionLabel={(option) => option.district_name} // Display district name in the dropdown
                isOptionEqualToValue={(option, value) => option._id === value} // Match the _id of the option to the selected value
                renderInput={(params) => (
                  <TextField {...params} label="District" size="small" />
                )}
                // Enable clearable button for resetting the selection
                disableClearable={false} // Allow the user to clear the selection
              />
            </FormControl>
          </Grid>}
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenAddModal}
              sx={{ backgroundColor: "#e0e0e0" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* Table Content */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State </TableCell>
              <TableCell>District </TableCell>
              <TableCell> Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTowns.length > 0 ? (
              filteredTowns.map((category, index) => (
                <TableRow key={category._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{category.country.country_name}</TableCell>
                  <TableCell>{getStateName(category.state)}</TableCell>
                  <TableCell>{getDistrictName(category.district)}</TableCell>
                  <TableCell>{category.Town_name}</TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(category._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(category._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No towns found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      {totalItems > 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {/* Deletion Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this town?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDelete(selectedCategoryId)}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Modal */}
      {openEditModal && (
        <EditTown
          open={openEditModal}
          onClose={handleCloseEditModal}
          townId={selectedCategoryId}
          onUpdate={() => {
            setSnackbarMessage("Town updated successfully");
            setOpenSnackbar(true);
            fetchCategories();
            handleCloseEditModal();
          }}
        />
      )}
      {/* Add Modal */}
      {openAddModal && (
        <AddTown
          isOpen={openAddModal}
          onClose={handleCloseAddModal}
          onAdd={() => {
            setSnackbarMessage("Town added successfully");
            setOpenSnackbar(true);
            fetchCategories();
            handleCloseAddModal();
          }}
        />
      )}
      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default TownList;
